//COLORS
$base-color: #24aa65;
$aqua: #038b8c;
$light-aqua: #03c6c7;
$blue: #33A1DE;
$orange: #FF7F50;
$light-green: #2bbf71;
$white:#ffffff;
$black-shed-1: #444444;
$black-shed-2: #6e6e6e;
$black-shed-3: #7f7e7e;
$black-shed-4: #6b6969;

$success: #00caa4;
$info: #59b2c9;
$warning: #ffd361;
$danger: #ff5a5a;
