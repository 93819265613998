/*Custom Fonts Start*/

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?vr5gp4');
  src: url('../fonts/icomoon.eot?vr5gp4#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?vr5gp4') format('truetype'),
  url('../fonts/icomoon.woff?vr5gp4') format('woff'),
  url('../fonts/icomoon.svg?vr5gp4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-size: 14px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.33;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-form-button:before {
  content: "\e900";
}

.icon-scroller:before {
  content: "\e901";
}

.icon-backup:before {
  content: "\e902";
}

.icon-c-panel:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-git-support:before {
  content: "\e905";
}

.icon-google-plus:before {
  content: "\e906";
}

.icon-gurantee:before {
  content: "\e907";
}

.icon-heading:before {
  content: "\e908";
}

.icon-latest-tech:before {
  content: "\e909";
}

.icon-pinterest:before {
  content: "\e90a";
}

.icon-scripts:before {
  content: "\e90b";
}

.icon-setup:before {
  content: "\e90c";
}

.icon-support:before {
  content: "\e90d";
}

.icon-twitter:before {
  content: "\e90e";
}

.icon-uptime:before {
  content: "\e90f";
}

/*Custom Fonts End*/