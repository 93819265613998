/* Buttons */
.btn {
  font-family: $open-sans;
  font-weight: 600;
  font-size: 14px;
  color: $white;
  text-align: center;
  transition: all 0.5s ease;
  text-transform: uppercase;
  border-radius: 0;
  border-top-right-radius: 10px;
  span {
    display: block;
  }
  &:focus {
    outline: none;
  }
  &.active, &:active {
    box-shadow: none;
  }
}

.btn-fill {
  background: $base-color;
  border: 2px solid $base-color;
  &:hover, &:focus {
    background: transparent;
    color: $base-color;
  }
}

.btn-bordered {
  background: transparent;
  border: 2px solid $white;
  &:hover, &:focus {
    background: $white;
    color: $base-color;
  }
}

.btn-black-bordered {
  color: $black-shed-1;
  background: transparent;
  border: 2px solid $black-shed-1;
  &:hover, &:focus {
    background: $black-shed-1;
    color: $white;
  }
}

.btn-green-bordered {
  color: $light-green;
  background: transparent;
  border: 2px solid $light-green;
  &:hover, &:focus {
    outline: unset;
    background: $light-green;
    color: $white;
  }
}

.btn-xs {
  font-size: 12px;
  padding: 6px 24px;
}

.btn-sm {
  padding: 6px 10px;
}

.btn-md {
  padding: 10px 30px;
}

.btn-lg {
  padding: 15px 25px;
  font-size: 16px;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-success {
  background: $success;
  border: 2px solid $success;
  &:hover, &:focus {
    background: transparent;
    color: $success;
    border-color: $success;
  }
}

.btn-info {
  background: $info;
  border: 2px solid $info;
  &:hover, &:focus {
    background: transparent;
    color: $info;
    border-color: $info;
  }
}

.btn-warning {
  background: $warning;
  border: 2px solid $warning;
  &:hover, &:focus {
    background: transparent;
    color: $warning;
    border-color: $warning;
  }
}

.btn-danger {
  background: $danger;
  border: 2px solid $danger;
  &:hover, &:focus {
    background: transparent;
    color: $danger;
    border-color: $danger;
  }
}