/* Menu Start */

#view-code {
  color: #4183d7;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  top: 640px;
  left: 50%;
  margin-left: -35px;
}

div.screen {
  width: 320px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.list {
  margin-top: 36px;
  text-align: left;
}

div {
  &.burger {
    height: 25px;
    width: 40px;
    position: absolute;
    top: 0;
    cursor: pointer;
  }
  &.x, &.y, &.z {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 0px;
    background: #fff;
    border-radius: 2px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -ms-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
  }
  &.x, &.y, &.z {
    height: 3px;
    width: 26px;
  }
  &.y {
    top: 18px;
  }
  &.z {
    top: 37px;
  }
  &.reset-bar {
    top: 20px;
    -webkit-transition: all 70ms ease-out;
    -moz-transition: all 70ms ease-out;
    -ms-transition: all 70ms ease-out;
    -o-transition: all 70ms ease-out;
    transition: all 70ms ease-out;
  }
  &.rotate30 {
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -webkit-transition: all 50ms ease-out;
    -moz-transition: all 50ms ease-out;
    -ms-transition: all 50ms ease-out;
    -o-transition: all 50ms ease-out;
    transition: all 50ms ease-out;
  }
  &.rotate150 {
    -ms-transform: rotate(150deg);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
    -webkit-transition: all 50ms ease-out;
    -moz-transition: all 50ms ease-out;
    -ms-transition: all 50ms ease-out;
    -o-transition: all 50ms ease-out;
    transition: all 50ms ease-out;
  }
  &.rotate45 {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
  }
  &.rotate135 {
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
  }
  &.navbar {
    height: 73px;
    background: #385e97;
  }
  &.menu-bg {
    width: 320px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #40435b;
    opacity: 0;
    -webkit-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    -moz-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    -ms-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    -o-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
    &.animate {
      opacity: 0.9;
      -webkit-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
      -moz-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
      -ms-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
      -o-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
      transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
    }
  }
  &.menu {
    height: 100vh;
    width: 100%;
  }
}

.menu-splitL, .menu-splitR {
  left: 0px;
  width: 160px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 60px;
}

.reset-bar {
  display: block;
}

.menu-splitR {
  left: 160px;
  padding: 0;
}

div.menu ul li {
  list-style: none;
  width: 320px;
  margin-top: 25px;
  text-align: center;
  font-size: 19px;
  -webkit-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  a {
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
  }
}

section > div {
  transition: transform 1s;
  transform: translateX(0px);
}

div.menu li.animate {
  font-size: 19px;
  opacity: 1;
  -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}

.menu-splitL li {
  &:nth-of-type(1) {
    margin-left: 44px;
    transition-delay: 0.12s;
  }
  &.animate:nth-of-type(1) {
    margin-left: 0;
    transition-delay: 0.0s;
  }
  &:nth-of-type(2) {
    margin-left: 62px;
    transition-delay: 0.09s;
  }
  &.animate:nth-of-type(2) {
    margin-left: 0;
    transition-delay: 0.05s;
  }
  &:nth-of-type(3) {
    margin-left: 57px;
    transition-delay: 0.046s;
  }
  &.animate:nth-of-type(3) {
    margin-left: 0;
    transition-delay: 0.1s;
  }
  &:nth-of-type(4) {
    margin-left: 80px;
    transition-delay: 0.03s;
  }
  &.animate:nth-of-type(4) {
    margin-left: 0;
    transition-delay: 0.15s;
  }
  &:nth-of-type(5) {
    margin-left: 59px;
    transition-delay: 0.0s;
  }
  &.animate:nth-of-type(5) {
    margin-left: 0;
    transition-delay: 0.2s;
  }
  &:nth-of-type(6) {
    margin-left: 59px;
    transition-delay: 0.0s;
  }
  &.animate:nth-of-type(6) {
    margin-left: 0;
    transition-delay: 0.2s;
  }
  &:nth-of-type(7) {
    margin-left: 59px;
    transition-delay: 0.0s;
  }
  &.animate:nth-of-type(7) {
    margin-left: 0;
    transition-delay: 0.2s;
  }
}

.menu-splitR li {
  &:nth-of-type(1) {
    margin-left: -201px;
    transition-delay: 0.12s;
  }
  &.animate:nth-of-type(1) {
    margin-left: -160px;
    transition-delay: 0.0s;
  }
  &:nth-of-type(2) {
    margin-left: -220px;
    transition-delay: 0.069s;
  }
  &.animate:nth-of-type(2) {
    margin-left: -160px;
    transition-delay: 0.05s;
  }
  &:nth-of-type(3) {
    margin-left: -214px;
    transition-delay: 0.06s;
  }
  &.animate:nth-of-type(3) {
    margin-left: -160px;
    transition-delay: 0.1s;
  }
  &:nth-of-type(4) {
    margin-left: -240px;
    transition-delay: 0.03s;
  }
  &.animate:nth-of-type(4) {
    margin-left: -160px;
    transition-delay: 0.15s;
  }
  &:nth-of-type(5) {
    margin-left: -217px;
    transition-delay: 0.0s;
  }
  &.animate:nth-of-type(5) {
    margin-left: -160px;
    transition-delay: 0.2s;
  }
  &:nth-of-type(6) {
    margin-left: -217px;
    transition-delay: 0.0s;
  }
  &.animate:nth-of-type(6) {
    margin-left: -160px;
    transition-delay: 0.2s;
  }
  &:nth-of-type(7) {
    margin-left: -217px;
    transition-delay: 0.0s;
  }
  &.animate:nth-of-type(7) {
    margin-left: -160px;
    transition-delay: 0.2s;
  }
}

/* Menu End */